export const USER_PREFERENCES = 'UserPreferences';
export const REQUEST_CANCEL_MESSAGE = 'CANCEL';

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'h:mm A';
export const TOOLTIP_DATE_FORMAT = 'DD/MM/YYYY h:mm A';

export const SUBSCRIPTION_TYPE = {
  MONTHLY: 1,
  YEARLY: 2,
  LIFE_TIME: 3
};

export const SUBSCRIPTION_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  UNPAID: 'UNPAID',
  CANCELED: 'CANCELED',
  ENDED: 'ENDED'
};

export const subscriptionsList = [
  {
    id: 'Starter_Yearly_appsumo',
    planName: 'Teamcamp Ultimate Lifetime Plan',
    planDescription: 'Teamcamp Ultimate Lifetime Plan',
    subscriptionType: 3,
    amount: 48,
    users: 3
  },
  {
    id: 'Starter_Monthly',
    planName: 'Starter Monthly',
    planDescription: 'Salescamp Starter Monthly',
    subscriptionType: 1,
    productId: 'price_1JSS9rSGhXoH0twD2UPxbBVy',
    amount: 12
  },
  {
    id: 'Plus_Monthly',
    planName: 'Plus Monthly',
    planDescription: 'Salescamp Plus Monthly',
    subscriptionType: 1,
    productId: 'price_1JSSAkSGhXoH0twDdQVzrtgv',
    amount: 29
  },
  {
    id: 'Pro_Monthly',
    planName: 'Pro Monthly',
    planDescription: 'Salescamp Pro Monthly',
    subscriptionType: 1,
    productId: 'price_1JSSBxSGhXoH0twDLw2fA3aI',
    amount: 49
  },
  {
    id: 'Enterprise_Monthly',
    planName: 'Enterprise Monthly',
    planDescription: 'Salescamp Enterprise Monthly',
    subscriptionType: 1,
    productId: 'price_1JSSDKSGhXoH0twDsRTMkonK',
    amount: 99
  },
  {
    id: 'Starter_Yearly',
    planName: 'Starter Yearly',
    planDescription: 'Salescamp Starter Yearly',
    subscriptionType: 2,
    productId: 'price_1JSS9rSGhXoH0twDxkgYtqt8',
    amount: 9
  },
  {
    id: 'Plus_Yearly',
    planName: 'Plus Yearly',
    planDescription: 'Salescamp Plus Yearly',
    subscriptionType: 2,
    productId: 'price_1JSSAkSGhXoH0twDiLCYbWbO',
    amount: 24
  },
  {
    id: 'Pro_Yearly',
    planName: 'Pro Yearly',
    planDescription: 'Salescamp Pro Yearly',
    subscriptionType: 2,
    productId: 'price_1JSSBxSGhXoH0twDPUV44DpW',
    amount: 39
  },
  {
    id: 'Enterprise_Yearly',
    planName: 'Enterprise Yearly',
    planDescription: 'Salescamp Enterprise Yearly',
    subscriptionType: 2,
    productId: 'price_1JSSDKSGhXoH0twDoKA0ALDM',
    amount: 79
  },
  {
    id: 'Starter_Lifetime_dealify',
    planName: 'Salescamp Starter Plan Lifetime',
    planDescription: 'Salescamp Starter Plan Lifetime - Dealify',
    subscriptionType: 3,
    amount: 89,
    users: 3
  },
  {
    id: 'Plus_Lifetime_dealify',
    planName: 'Salescamp Plus Plan Lifetime',
    planDescription: 'Salescamp Plus Plan Lifetime - Dealify',
    subscriptionType: 3,
    amount: 249,
    users: 5
  },
  {
    id: 'Pro_Lifetime_dealify',
    planName: 'Salescamp Pro Plan Lifetime',
    planDescription: 'Salescamp Pro Plan Lifetime - Dealify',
    subscriptionType: 3,
    amount: 449,
    users: 10
  }
];
