import axios from 'axios';
import { saveToken, clearToken } from '../helpers/authTokenHelpers';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { clearAuthData, setAuthLoader, setAuthValidationError, setCurrentUser } from '../actions/authActions';
// import { REACT_APP_APIURL } from '../global/environment';
import { appInit, clearOrgReduxData } from './appService';
// var qs = require('querystring');

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthValidationError(''));
    if (!obj) {
      dispatchAuthValidationError('Username is Required', dispatch);
      return;
    } else if (!obj.username) {
      dispatchAuthValidationError('Username is Required', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthValidationError('Password is Required', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`https://teamcamp-back-end.vercel.app/api/admin/adminSignin`, obj);
    const { data } = response;
    if (data) {
      dispatch(setLoginDetail(data));
      await dispatch(appInit());
      return data;
    }
  } catch (e) {
    dispatchAuthError(getAPIErrorReason(e) || 'Unable to login please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Log user out
 */
export const logout = () => async (dispatch) => {
  // Call LogOut API to clear cookie and session
  // const options = { withCredentials: true };
  // axios.delete(`${REACT_APP_APIURL}/membership/logOff`, options);
  clearToken();
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  //clear reducer data
  dispatch(clearAuthData());
  dispatch(clearOrgReduxData());

  await UserPreferenceSingleton.removeInstance();
};

/**
 * @desc set login token and set user
 */
export const setLoginDetail = (item) => (dispatch) => {
  // save auth deteils and set token in header for request
  saveToken(item.access_token);
  UserPreferenceSingleton.getInstance().setCurrentUser(item);
  dispatch(setCurrentUser(item));
};

function dispatchAuthError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

function dispatchAuthValidationError(msg, dispatch) {
  dispatch(setAuthValidationError(msg));
}
