import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import Table from '../../components/Table/Table';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import CompanyDetailModal from '../Workspaces/companyDetailModal';
import AddSubscriptionModal from '../Subscriptions/AddSubscriptionModal';
import { getSubscriptionsByCompany } from '../../services/subscriptionServices';
import { getCompanyList } from '../../services/companyServices';
import { DATE_FORMAT } from '../../global/constants';
import { isEmpty } from '../../helpers/common';
import { CellLinkWrapper, CellWrapper, Name } from './styles';

const SORTED_FEILDS = 'sortedFeilds';
const TABNAME = 'workspaces';

const Workspaces = () => {
  const [isOpenCompanyDetailModal, setIsOpenCompanyDetailModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [isOpenAddSubscriptionModal, setIsOpenAddSubscriptionModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState('');
  const companySelector = useSelector((state) => state.company);
  const { list: companyList } = companySelector;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyList());
  }, [dispatch]);

  const tableColumns = useMemo(() => {
    return [{ Header: 'Created On', accessor: 'created_At' }];
  }, []);

  const sortedFeild = useMemo(() => {
    const sortedFeild = UserPreferenceSingleton.getInstance().getSettingValue(TABNAME, SORTED_FEILDS);
    return sortedFeild;
  }, []);

  const initialStateOfTable = useMemo(() => {
    if (sortedFeild?.fieldId && sortedFeild?.desc !== undefined) {
      const initialState = {
        sortBy: [
          {
            id: sortedFeild.fieldId,
            desc: sortedFeild.desc
          }
        ]
      };
      return initialState;
    }
    return undefined;
  }, [sortedFeild?.desc, sortedFeild?.fieldId]);

  const onOpenCompanyDetailModal = useCallback((item) => {
    setIsOpenCompanyDetailModal(true);
    setSelectedCompany(item);
  }, []);

  const onCloseCompanyDetailModal = useCallback(() => {
    setIsOpenCompanyDetailModal(false);
  }, []);

  const tableData = useMemo(() => {
    const columns = [
      {
        Header: () => (
          <CellWrapper>
            <Name>Name</Name>
          </CellWrapper>
        ),
        accessor: 'name',
        Cell: (props) => {
          return (
            <CellWrapper>
              <CellLinkWrapper
                to={{ pathname: `` }}
                onClick={() => {
                  onOpenCompanyDetailModal(props?.cell?.row?.original);
                }}>
                {props?.cell?.row?.original.name}
              </CellLinkWrapper>
            </CellWrapper>
          );
        }
      },
      ...tableColumns
    ];

    const data = companyList?.map((company) => {
      const rowData = {
        id: company.id,
        name: company?.name,
        created_At: moment(company?.created_At).format(DATE_FORMAT)
      };
      return rowData;
    });
    return {
      columns,
      data
    };
  }, [onOpenCompanyDetailModal, tableColumns, companyList]);

  const onOpenAddSubscriptionModal = useCallback(() => {
    setIsOpenAddSubscriptionModal(true);
  }, []);

  const onCloseAddSubscriptionModal = useCallback(() => {
    setIsOpenAddSubscriptionModal(false);
    if (selectedSubscription) {
      setSelectedSubscription('');
    }
  }, [selectedSubscription]);

  const onSaveSubscription = useCallback(() => {
    dispatch(getSubscriptionsByCompany(selectedCompany?.id));
    onCloseAddSubscriptionModal();
  }, [dispatch, onCloseAddSubscriptionModal, selectedCompany?.id]);

  const onSelectSubscription = useCallback((item) => {
    setSelectedSubscription(item?.id);
    setIsOpenAddSubscriptionModal(true);
  }, []);

  return (
    <>
      <Table
        columns={tableData?.columns || []}
        data={tableData?.data || []}
        tabName={TABNAME}
        initialState={initialStateOfTable}></Table>
      <Modal
        open={isOpenCompanyDetailModal}
        onClose={onCloseCompanyDetailModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <CompanyDetailModal
          onClose={onCloseCompanyDetailModal}
          company={selectedCompany}
          openAddSubscriptionModal={onOpenAddSubscriptionModal}
          onSelectItem={(item) => onSelectSubscription(item)}
        />
      </Modal>
      <Modal
        open={isOpenAddSubscriptionModal}
        onClose={onCloseAddSubscriptionModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        BackdropProps={{ invisible: true }}>
        <AddSubscriptionModal
          onSave={onSaveSubscription}
          onClose={onCloseAddSubscriptionModal}
          company={selectedCompany}
          itemId={!isEmpty(selectedSubscription) ? selectedSubscription : undefined}
        />
      </Modal>
    </>
  );
};

export default Workspaces;
