import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { REACT_APP_APIURL } from '../global/environment';
import {
  setCompanySubscriptions,
  setSubscriptionItem,
  setSubscriptionLoader,
  setSubscriptionPlans,
  setSubscriptions
} from '../actions/subscriptionActions';

/**
 * @desc Subscriptions - Get Subscriptions
 * @param {*}
 */
export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/admin/Subscriptions`);
    const { data } = response;
    if (data) {
      dispatch(setSubscriptions(data));
      return true;
    }
  } catch (e) {
    dispatchSubscriptionError(getAPIErrorReason(e) || 'Unable to get subscriptions please try again', dispatch);
    return false;
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};

/**
 * @desc Subscriptions - Get Subscriptions By Company
 * @param {*} companyId
 */
export const getSubscriptionsByCompany = (companyId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));
    const params = {
      companyId
    };
    const response = await axios.get(
      `https://teamcamp-back-end.vercel.app/api/admin/subscription/getSubscriptionList`,
      { params }
    );
    const { data } = response;
    if (data) {
      dispatch(setCompanySubscriptions(data));
      return true;
    }
  } catch (e) {
    dispatchSubscriptionError(
      getAPIErrorReason(e) || 'Unable to get subscriptions by company please try again',
      dispatch
    );
    return false;
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};

/**
 * @desc Get Subscription Plans
 */
export const getSubscriptionPlans = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/admin/Subscriptions/GetPlans`);
    const { data } = response;
    if (data) dispatch(setSubscriptionPlans(data));
    return true;
  } catch (e) {
    dispatchSubscriptionError(getAPIErrorReason(e) || 'Unable to get Subscription plans please try again', dispatch);
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};
/**
 * @desc Get Subscription Item
 */
export const getSubscriptionItem = (subscriptionId) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));
    const params = {
      subscriptionId
    };
    const response = await axios.get(
      `https://teamcamp-back-end.vercel.app/api/admin/subscription/getSubscriptionDetails`,
      { params }
    );
    const { data } = response;
    if (data) {
      dispatch(setSubscriptionItem(data));
      return data;
    }
  } catch (e) {
    dispatchSubscriptionError(getAPIErrorReason(e) || 'Unable to get Subscription plans please try again', dispatch);
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};
/**
 * @desc Add Admin Subscription
 */
export const addAdminSubscription = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));
    const response = await axios.post(
      `https://teamcamp-back-end.vercel.app/api/admin/subscription/postSubscription`,
      payload
    );
    const { data } = response;
    const { message } = data;
    if (data) {
      dispatchSubscriptionSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSubscriptionError(getAPIErrorReason(e) || 'Unable to add admin subscription please try again', dispatch);
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};

/**
 * @desc Update Admin Subscription
 */
export const updateAdminSubscription = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSubscriptionLoader(true));
    const response = await axios.put(
      `https://teamcamp-back-end.vercel.app/api/admin/subscription/putSubsciption`,
      payload
    );
    const { data } = response;
    const { message } = data;
    if (data) {
      dispatchSubscriptionSuccess(message, dispatch);
      return true;
    }
  } catch (e) {
    dispatchSubscriptionError(getAPIErrorReason(e) || 'Unable to update admin subscription please try again', dispatch);
  } finally {
    dispatch(setSubscriptionLoader(false));
  }
};

function dispatchSubscriptionError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
function dispatchSubscriptionSuccess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}
