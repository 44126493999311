import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '../../components/Button';
import Input from '../../components/Input';
import DropDown from '../../components/DropDown/DropDown';
import { DatePicker } from '../../components/DateTime';
import { SUBSCRIPTION_TYPE, subscriptionsList } from '../../global/constants';
import Icon from '../../components/Icon/Icon';
import {
  addAdminSubscription,
  getSubscriptionItem,
  updateAdminSubscription
} from '../../services/subscriptionServices';
import { clearSubscriptionItem, updateSubscriptionItem } from '../../actions/subscriptionActions';
import { isEmpty } from '../../helpers/common';
import Validation from '../../components/Validation/Validation';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import {
  MainContact,
  TitleView,
  TitleText,
  BottomView,
  Form,
  LeftWrapper,
  ListWrappper,
  DropDownTitle,
  marginBottom,
  FormWrapper,
  DateTimeWrapper,
  TextArea,
  ButtonWrapper,
  InputWrapperModal,
  LabelInput,
  Left,
  Requierd
} from './styles';

const PLAN_STATUS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Unpaid', value: 'UNPAID' },
  { label: 'Canceled', value: 'CANCELED' },
  { label: 'Ended', value: 'ENDED' }
];

const PAYMENT_TYPE = [
  { label: 'Stripe', value: 'STRIPE' },
  { label: 'Paddle', value: 'PADDLE' },
  { label: 'Key', value: 'KEY' },
  { label: 'Cash', value: 'CASH' },
  { label: 'Bank Transfer', value: 'BANK TRANSFER' }
];

const AddSubscriptionModal = ({ onClose, itemId, onSave, company }) => {
  const subscriptionSelector = useSelector((state) => state.subscription);
  const { loading, subscriptionItem } = subscriptionSelector;
  const [plan, setPlan] = useState();
  const [isGetPlan, setIsGetPlan] = useState(false);

  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (!isGetPlan) {
      // await dispatch(getSubscriptionPlans());
      setIsGetPlan(true);
    }
    if (company) {
      dispatch(updateSubscriptionItem({ propsName: 'companyId', value: company?.id }));
    }
    if (itemId) {
      const result = await dispatch(getSubscriptionItem(itemId));
      if (result) {
        const planItem = {
          planName: result?.planName,
          planDescription: result?.planDescription
        };
        setPlan(planItem);
      }
    }
  }, [company, dispatch, isGetPlan, itemId]);
  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearSubscriptionItem());
    };
  }, [dispatch]);

  useEffect(() => {
    let end;
    if (plan?.subscriptionType === SUBSCRIPTION_TYPE.MONTHLY) {
      end = new Date(moment(subscriptionItem?.start).add(1, 'months').calendar());
    } else if (plan?.subscriptionType === SUBSCRIPTION_TYPE.LIFE_TIME) {
      end = new Date(moment(subscriptionItem?.start).add(10, 'years').calendar());
    } else if (plan?.subscriptionType === SUBSCRIPTION_TYPE.YEARLY) {
      end = new Date(moment(subscriptionItem?.start).add(1, 'years').calendar());
    }
    if (end) {
      dispatch(updateSubscriptionItem({ propsName: 'end', value: end }));
    }
  }, [dispatch, plan, subscriptionItem?.start]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let error = {};
      setError({});
      if (!subscriptionItem?.planName || !subscriptionItem?.planName?.trim() === '') {
        error['planName'] = 'Required';
      }
      if (isEmpty(subscriptionItem?.amount)) {
        error['amount'] = 'Required';
      }
      if (isEmpty(subscriptionItem?.users)) {
        error['users'] = 'Required';
      }
      if (!subscriptionItem?.status || !subscriptionItem?.status?.trim() === '') {
        error['status'] = 'Required';
      }
      if (!subscriptionItem?.start) {
        error['start'] = 'Required';
      }
      if (!subscriptionItem?.end) {
        error['end'] = 'Required';
      }
      if (!isEmpty(error)) {
        setError(error);
        return;
      }
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        ...subscriptionItem,
        start: new Date(subscriptionItem?.start).toISOString(),
        end: new Date(subscriptionItem?.end).toISOString(),
        userId: userDetails?.id
      };
      let result;
      if (itemId) {
        result = await dispatch(updateAdminSubscription(payload));
      } else {
        result = await dispatch(addAdminSubscription(payload));
      }
      if (result) {
        if (onSave) onSave();
        if (onClose) onClose();
      }
    },
    [dispatch, itemId, onClose, onSave, subscriptionItem]
  );

  const onChangeInput = useCallback(
    (propsName, value) => {
      dispatch(updateSubscriptionItem({ propsName, value }));
    },
    [dispatch]
  );
  const onChangePlan = useCallback(
    (value) => {
      setPlan(value);
      onChangeInput('planName', value?.planName);
      onChangeInput('planDescription', value?.planDescription);
      onChangeInput('amount', value?.amount || '');
      onChangeInput('users', value?.users || '');
    },
    [onChangeInput]
  );

  return (
    <MainContact>
      <TitleView>
        <Left>
          <Icon name='icon-max-mius' color='var(--gray-icon-color)' fontSize='22' />
          <TitleText>{itemId ? 'Edit' : 'New'} Subscription</TitleText>
        </Left>
        <Icon name='icon-close' color='var(--gray-icon-color)' fontSize='20' onClick={onClose} title={'Close'} />
      </TitleView>

      <Form onSubmit={onSubmit}>
        <FormWrapper>
          <ListWrappper>
            <DropDownTitle>
              Select Plan<Requierd>*</Requierd>
            </DropDownTitle>
            <DropDown
              options={subscriptionsList || []}
              labelField={'planName'}
              valueField={'planName'}
              value={plan}
              hasObject={true}
              onChange={(value) => {
                onChangePlan(value);
              }}
              placeholder={'Select Plan'}
            />
          </ListWrappper>
          <ListWrappper>
            <Input
              marginBottom={marginBottom}
              title='Plan Name'
              isPrimarySmall
              autocomplete='off'
              placeholder='Plan Name'
              value={subscriptionItem?.planName}
              onChange={(e) => {
                onChangeInput('planName', e.target.value);
              }}
            />
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>Description</DropDownTitle>
            <TextArea
              type='text'
              name={'Description'}
              placeholder={'Description'}
              title='Description'
              value={subscriptionItem?.planDescription}
              onChange={(e) => {
                onChangeInput('planDescription', e.target.value);
              }}
            />
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>
              Amount<Requierd>*</Requierd>
            </DropDownTitle>
            <Input
              marginBottom={marginBottom}
              isPrimarySmall
              type='number'
              autocomplete='off'
              placeholder='Amount'
              value={subscriptionItem?.amount}
              onChange={(e) => {
                onChangeInput('amount', e.target.value);
              }}
            />
          </ListWrappper>

          <ListWrappper>
            <DropDownTitle>
              User Count<Requierd>*</Requierd>
            </DropDownTitle>
            <Input
              marginBottom={marginBottom}
              isPrimarySmall
              type='number'
              autocomplete='off'
              placeholder='User Count'
              value={subscriptionItem?.users}
              onChange={(e) => {
                onChangeInput('users', e.target.value);
              }}
            />
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>
              Status<Requierd>*</Requierd>
            </DropDownTitle>
            <DropDown
              options={PLAN_STATUS || []}
              labelField={'label'}
              valueField={'value'}
              value={subscriptionItem?.status}
              onChange={(item) => {
                onChangeInput('status', item?.value);
              }}
              placeholder={'Select Status'}
            />
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>Start Date</DropDownTitle>
            <DateTimeWrapper>
              <DatePicker
                autoOk={true}
                value={subscriptionItem?.start}
                className='DatePickerWrapper'
                onChange={(value) => {
                  onChangeInput('start', value);
                }}
              />
            </DateTimeWrapper>
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>End Date</DropDownTitle>
            <DateTimeWrapper>
              <DatePicker
                autoOk={true}
                value={subscriptionItem?.end}
                className='DatePickerWrapper'
                onChange={(value) => {
                  onChangeInput('end', value);
                }}
              />
            </DateTimeWrapper>
          </ListWrappper>
          <ListWrappper>
            <DropDownTitle>Payment Type</DropDownTitle>
            <DropDown
              options={PAYMENT_TYPE || []}
              labelField={'label'}
              valueField={'value'}
              value={subscriptionItem?.paymentMode}
              onChange={(item) => {
                onChangeInput('paymentMode', item?.value);
              }}
              placeholder={'Select Payment Type'}
            />
          </ListWrappper>
          <Input
            marginBottom={marginBottom}
            title='Payment Reference'
            isPrimarySmall
            type='text'
            autocomplete='off'
            placeholder='Payment Reference'
            value={subscriptionItem?.paymentReference}
            onChange={(e) => {
              onChangeInput('paymentReference', e.target.value);
            }}
          />
          <InputWrapperModal>
            <LabelInput>
              Phone Call access ?
              <input
                type='checkbox'
                checked={subscriptionItem?.isPhoneCall}
                onChange={() => {
                  onChangeInput('isPhoneCall', !subscriptionItem?.isPhoneCall);
                }}
              />
              <span className='checkmark'></span>
            </LabelInput>
          </InputWrapperModal>
          {!isEmpty(error) && <Validation error={'* This fields are requierd'}></Validation>}
        </FormWrapper>
        <BottomView>
          <LeftWrapper>
            <ButtonWrapper>
              <Button primary={true} type='submit' title='Submit' loading={loading} />
            </ButtonWrapper>
            <Button secondary={true} title='Cancel' type='button' onClick={onClose} />
          </LeftWrapper>
        </BottomView>
      </Form>
    </MainContact>
  );
};

export default AddSubscriptionModal;
